import React from "react";

import { navigate } from "gatsby";

import API from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";
import Amplify from "aws-amplify";
import CardHeading from "../components/layout/CardHeading";
import Layout from "../components/layout/Layout";
import aws_exports from "../aws-exports";

Amplify.configure(aws_exports);
Amplify.register(Auth);
Amplify.register(API);

const heading = {
  title: "Sample Page",
  desc: "This is sample",
};
export const checkAuth = async () => {
  let user = {};

  try {
    const [auth, creds] = await Promise.all([
      Auth.currentAuthenticatedUser(),
      Auth.currentCredentials(),
    ]);
    if (creds.authenticated) {
      user = {
        username: auth.username,
        email: auth.attributes.email,
        email_verified: auth.attributes.email_verified,
      };
    }
  } catch (e) {
    navigate("/");
  }
};

const NotFoundPage = () => (
  <Layout data-test="sample-component">
    <div className="error-message">
      <CardHeading heading={heading} />
      <button data-test="sample-component-button" onClick={checkAuth}>
        Continue
      </button>
    </div>
  </Layout>
);

export default NotFoundPage;
